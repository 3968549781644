import { CurrencyCode } from '@buggy/shared'
import { z } from 'zod'
import {
  UNKNOWN_COUNTRY_CODE,
  countryCodeEnumSchema,
} from '@/constants/country-code'
import { CountryCode } from '@/constants/country-currency'
import { LanguageCodeType } from '@/constants/search/language-code'
import { SupportedLanguages } from '@/i18n/config'
import { KolSource } from '@/types/api/search'
import { Nullable } from '@/types/nullable'
import { KpiSchema } from '@/types/schema/kpi-schema'
import { PlatformType } from '@/types/schema/report-schema'
import { PlatformShortcode } from '@/utils/convert-platform'
import { Potential } from '@/utils/detail/potential'

export interface Link {
  platformType: number
  /**@description type is platformShortCode */
  type: string
  url: string
  thumbnailUrl: string
  isProfileActived: boolean
  permanentURL: string
  description: string | null
  nameList: string[] | null
}

interface Url {
  _id: string
  type: string
  url: string
}

interface CoworkPrice {
  content: string
  id: string
  type: string
  v1?: number
  v2?: number
}

export enum QuotationType {
  Post = 'post',
  Video = 'video',
}

export interface PriceRange {
  /**@description: 四捨五入後的整數 */
  minPrice: number
  /**@description: 四捨五入後的整數, 80w 粉絲數以上會是 null, i18n 顯示 minPrice 以上 e.g. $350,000 以上  */
  maxPrice: Nullable<number>
  currency: CurrencyCode
}

export type Quotations = Nullable<Record<QuotationType, PriceRange>>

export type SupportedQuotationLang =
  | SupportedLanguages.zhTW
  | SupportedLanguages.ja
  | SupportedLanguages.enUS

/**@description: 公開頁 Quotations */
export type QuotationsByLang = Nullable<
  Record<SupportedQuotationLang, Quotations>
>

export interface MatchedPost {
  publishTime: string
  titleContent: string
  postId: string
  postType: string
  platformType: PlatformType
  thumbnail: string
  crawledId: string
  igPostId: string
  link: string
}

/**
 * ResponseSchema from API
 *
 * @example
 * axios.get<KolSchema[]>(list)
 */

// TODO: parse type
export interface KolSchemaRemote {
  id: string
  traceId: number
  /** e.g. `'Gina|水之GINA|Gina Hello'` */
  name: string
  thumbnail: string
  gender: string
  /**
   * @deprecated Use customizedTagsNames instead
   */
  property: string[]
  customizedTagsNames: string[]
  specialty: string
  description: string
  customTag: string | null
  verified: boolean
  isRadarStar: boolean
  visibility: boolean
  /** 經營平台 */
  links: Link[]
  follower: number
  urls: Url[]
  kpi: KpiSchema[]
  /** When postKeyword given and isVip */
  postCount?: number
  activeLink: string
  maxInteractiveRate3m: number
  maxFansUpRate3m: number
  maxAvgPvRate3m: number
  agentInfo: any
  crawledDescription: string | null
  cowork?: {
    price: CoworkPrice[]
  }
  statistics: any
  fans: any
  editable?: boolean
  portfolioStatus: any
  potential: Potential
  score?: number
  countryCode?: Nullable<CountryCode>
  recommend: 'positive' | 'negative' | ''
  resourcesInvolved?: any[]
  isCommunityKol?: boolean
  isCollectionKol: boolean
  snapshotAt: string | null
  snapshotUpdatedAt: string | null
  /** 網紅接案估價 */
  quotations: Quotations | QuotationsByLang
  isUnlocked: Nullable<boolean>
  matchedPosts: MatchedPost[]
  latestPosts: MatchedPost[]
  /**
   * 不是額度制會是 null
   */
  withNewDataToUnlock: Nullable<boolean>
  languages: Nullable<
    {
      name: LanguageCodeType
      weight: string
    }[]
  >
  similarKolPlatform: Nullable<PlatformShortcode>
  dcIdHashed?: string
  /**
   * @todo Remove optional after API response is updated
   */
  isAddedToDirectory?: boolean
}

export const KolMetricPerformanceSummarySchema = z.object({
  summary: z.string().nullable(),
})

export type KolMetricPerformanceSummary = z.infer<
  typeof KolMetricPerformanceSummarySchema
>

/**
 * ReduxSchema
 *
 * @example
 * const kolEx = useKolSchemaEx(kolResourceSchema)
 */
export interface KolSchemaModel extends KolSchemaRemote {
  /** e.g. `'Gina'` 截取從 `'Gina|水之GINA|Gina Hello'` */
  name: string
  /** e.g. `['水之GINA', 'Gina Hello']` 截取從 `'Gina|水之GINA|Gina Hello'` */
  subName: string[]
}

/**
 * RemoteSchema 轉 ReduxSchema
 *
 * @example
 * const kolRemote: KolSchemaRemote
 *
 * kol.subName === undefined
 *
 * const kolModel = applyKolSchemaModel(kol)
 *
 * kolModel.subName: string
 */
export const applyKolSchemaModel = (kol: KolSchemaRemote): KolSchemaModel => {
  const [name, ...subName] = kol.name.split('|')

  return {
    ...kol,
    name,
    subName,
  }
}

type Sort =
  | 'recommend'
  | 'followerCount'
  | 'maxFansUpRate3Month'
  | 'maxInteractiveRate3Month'
  | 'maxAvgPvRate3Month'
  | 'matchedPost'

export type SearchSort = Sort | string

export const keywordSuggestionsResponseSchema = z.object({
  data: z.object({
    keyword_suggestions: z.string().array(),
  }),
})

export type KeywordSuggestionsResponse = z.infer<
  typeof keywordSuggestionsResponseSchema
>

const crossCountrySuggestionSchema = z.discriminatedUnion('source', [
  z.object({
    source: z.literal(KolSource.DataCenter),
    id: z.string(),
    name: z.string(),
    follower: z.number(),
    country_code: z.literal(UNKNOWN_COUNTRY_CODE).or(countryCodeEnumSchema), // 跨國網紅的 country code 有可能是 unknown，且是預期的 ref: https://www.notion.so/ikala/Staging-Search-105fb0834d6e807092f6cc8d0d160215?pvs=4
  }),
  z.object({
    source: z.literal(KolSource.IqData),
    name: z.string(),
    follower: z.number(),
    explore_url: z.string(),
    explore_thumbnail: z.string(),
    platform_user_id: z.string(),
  }),
])

export const fetchCrossCountrySuggestionsResponseSchema = z.object({
  data: z.array(crossCountrySuggestionSchema),
})

export type FetchCrossCountrySuggestionsResponse = z.infer<
  typeof fetchCrossCountrySuggestionsResponseSchema
>

import { CurrencyCode } from '@buggy/shared'
import { FetchArgs, FetchBaseQueryError } from '@reduxjs/toolkit/query/react'
import { isEmpty, omit } from 'lodash-es'
import { api, InfluencerAPITagType, UserAPITagType } from '@/api/api'
import { CountryCode } from '@/constants/country-currency'
import { UserUserContractStatus } from '@/types/api-v2-types'
import {
  ModelResource,
  ModelServiceWithEndAt,
  ModelWorkspaceAuth,
} from '@/types/mock-api-types'
import { Nullable } from '@/types/nullable'
import { ListResource } from '@/types/resource-type'
import {
  UserMetadataResponse,
  userMetadataResponseSchema,
} from '@/types/schema/api/user'
import {
  applyKolSchemaModel,
  KolSchemaModel,
  KolSchemaRemote,
} from '@/types/schema/kol-schema'
import { UserMetadata } from '@/types/schema/user-metadata'
import { UserSchema } from '@/types/schema/user-schema'
import { DurationType } from '@/utils/get-duration'
import { camelizeKeys, decamelizeKeys } from '@/utils/humps'
import { tokenProvider } from '@/utils/token-provider'

export enum WorkspaceMemberStatus {
  Admin = 'admin',
  Editor = 'editor',
  Guest = 'guest',
  Viewer = 'viewer',
  NoAuth = '-',
}

export enum WorkspaceType {
  Free = 'free',
  Trial = 'trial', //試用版由線上填問卷產生
  Trial202408 = 'trial_202408', // 試用版由 admin 後台產生
  Premium202408 = 'premium_202408',
  Advanced202408 = 'advanced_202408',
  Corporate = 'corporate', // 企業版額度方案
  Enterprise = 'enterprise', // 企業版吃到飽方案
  Internal = 'internal', // 內部使用
  /**
   * @deprecated
   */
  Premium = 'premium',
  /**
   * @deprecated.
   */
  Advanced = 'advanced',
  /**
   * @deprecated
   */
  Vip = 'vip',
  /**
   * @deprecated
   */
  PaymentVip = 'payment-vip',
  /**
   * @deprecated
   */
  VipTrial = 'vip-trial',
  /**
   * @deprecated
   */
  EnterpriseTrial = 'enterprise-trial',
}

export enum FeatureFlag {
  DualWheel = 'DualWheel',
}

export interface Workspace {
  id: number
  businessSize: Nullable<string>
  company: Nullable<string>
  name: string
  isTest: Nullable<boolean>
  iconUrl: Nullable<string>
  isTrial: Nullable<boolean>
  industry: Nullable<string>
  memberAmount: number
  memberStatus: WorkspaceMemberStatus
  workspaceType: Nullable<WorkspaceType>
  workspaceAuth: Nullable<ModelWorkspaceAuth>
  currencyCode: Nullable<CurrencyCode>
  countryCode: Nullable<CountryCode>
  featureFlags: FeatureFlag[]
  availableServices: ModelServiceWithEndAt[]
  availableResources: ModelResource[]
  usagePurpose: Nullable<string>
  purposeDescription: Nullable<string>
}

export interface Subscription {
  workspaceType: WorkspaceType
  serviceEndTime: Nullable<string>
  /**
   * Will be null if workspaceType is Internal or Enterprise
   */
  planId: Nullable<number>
  /**
   * Will be null if workspaceType is Internal or Enterprise
   */
  currency: Nullable<CurrencyCode>
  /**
   * Will be null if workspaceType is Internal or Enterprise
   */
  amount: Nullable<string>
  /**
   * Will be null if workspaceType is Internal or Enterprise
   */
  duration: Nullable<DurationType>
  /**
   * Will be null if workspaceType is Internal or Enterprise
   */
  isSubscribed: Nullable<boolean>
}

export interface UserStatus {
  currentWorkspaceId: number
  userId: string
  currentWorkspace: Workspace
  workspaces: Workspace[]
  currentPlan: Nullable<Subscription>
  queuedTrialCode: Nullable<string>
}

interface FetchAgentKolsRequest {
  page: number
}

type FetchAgentKolRemoteResponse = ListResource<KolSchemaRemote>
type FetchAgentKolsResponse = ListResource<KolSchemaModel>

interface FetchKolBasicInfoRequest {
  kolUUID: string
}

interface FetchKolBasicInfoResponse {
  name: string[]
  gender: string
  description: string
}

export interface Price {
  type: string
  content: string
  value1?: number
  value2?: number
}

interface EditKolBasicInfoRequest {
  kolUUID: string
  name: string[]
  gender: string
  description: string
}

interface FetchKolCooperateResponse {
  type: string[]
  note: string
  prices: Price[]
}

interface FetchKolCooperateRequest {
  kolUUID: string
  note: string
  type: string[]
  prices: Price[]
}

type PubNubLoginResponse = {
  data: {
    token: string
  }
}

export interface Recipient {
  id: number
  createdAt: string
  updatedAt: string
  userId: number
  name: string
  phone: string
  address: string
}

interface EmailCheckRequest {
  oAuthType?: string
  email: string
}

export const userApi = api.injectEndpoints({
  endpoints: (builder) => ({
    fetchUserStatus: builder.query<UserStatus | undefined, void>({
      async queryFn(arg, queryApi, extraOptions, baseQuery) {
        if (isEmpty(tokenProvider())) {
          return { data: undefined }
        }

        const result = await baseQuery('/user/status')

        return result.data
          ? {
              data: camelizeKeys(
                result.data as object,
              ) as unknown as UserStatus,
            }
          : { error: result.error as FetchBaseQueryError }
      },
      providesTags: [UserAPITagType.UserStatus],
    }),
    fetchUserInfo: builder.query<UserSchema | undefined, void>({
      async queryFn(arg, queryApi, extraOptions, baseQuery) {
        if (isEmpty(tokenProvider())) {
          return { data: undefined }
        }

        const result = await baseQuery('/user/me')

        return result.data
          ? {
              data: camelizeKeys(
                result.data as object,
              ) as unknown as UserSchema,
            }
          : { error: result.error as FetchBaseQueryError }
      },
      providesTags: [UserAPITagType.UserInfo],
    }),
    updateUserInfo: builder.mutation<UserSchema, Partial<UserSchema>>({
      query: (body): FetchArgs => ({
        url: '/user/me',
        body: decamelizeKeys(body),
        method: 'PUT',
      }),
      transformResponse: (response: object) => {
        return camelizeKeys(response) as unknown as UserSchema
      },
      invalidatesTags: [UserAPITagType.UserInfo],
    }),
    checkEmailAvailable: builder.mutation<void, EmailCheckRequest>({
      query: (data) => {
        return {
          url: '/user/email',
          method: 'POST',
          body: data,
        }
      },
    }),
    fetchContractStatus: builder.query<UserUserContractStatus, void>({
      query: () => {
        return '/user/contractStatus'
      },
      transformResponse: (response: object) => {
        return camelizeKeys(response) as unknown as UserUserContractStatus
      },
      providesTags: [UserAPITagType.ContractStatus],
    }),
    fetchRecipientInfo: builder.query<Recipient, void>({
      query: () => {
        return '/user/recipient-info'
      },
      transformResponse: (response: object) => {
        return camelizeKeys(response) as unknown as Recipient
      },
      providesTags: [UserAPITagType.RecipientInfo],
    }),
    updateRecipientInfo: builder.mutation<Recipient, Partial<Recipient>>({
      query: (recipient) => {
        return {
          url: '/user/recipient-info',
          body: recipient,
          method: 'PATCH',
        }
      },
      transformResponse: (response: object) => {
        return camelizeKeys(response) as unknown as Recipient
      },
      invalidatesTags: [UserAPITagType.RecipientInfo],
    }),
    fetchAgentKols: builder.query<
      FetchAgentKolsResponse,
      FetchAgentKolsRequest
    >({
      query: ({ page }) => {
        const params = {
          page,
          perPage: 10,
        }
        return {
          url: '/user/kols',
          params: decamelizeKeys(params),
        }
      },
      transformResponse: (response: object) => {
        const camelizeResponse = camelizeKeys(
          response,
        ) as unknown as FetchAgentKolRemoteResponse

        return {
          ...camelizeResponse,
          data: camelizeResponse.data.map((kol) => applyKolSchemaModel(kol)),
        }
      },
      providesTags: [UserAPITagType.AgentKolList],
    }),
    fetchKolBasicInfo: builder.query<
      FetchKolBasicInfoResponse,
      FetchKolBasicInfoRequest
    >({
      query: ({ kolUUID }) => {
        return {
          url: `/user/kols/${kolUUID}`,
        }
      },
      transformResponse: (response: object) => {
        return camelizeKeys(response) as unknown as FetchKolBasicInfoResponse
      },
      providesTags: [UserAPITagType.KolBasicInfo],
    }),
    editKolBasicInfo: builder.mutation<void, EditKolBasicInfoRequest>({
      query: (request) => {
        return {
          url: `/user/kols/${request.kolUUID}`,
          method: 'PUT',
          body: decamelizeKeys(omit(request, 'kolUUID')),
        }
      },
      invalidatesTags: [
        UserAPITagType.KolBasicInfo,
        UserAPITagType.AgentKolList,
        InfluencerAPITagType.InfluencerInfo,
      ],
    }),
    fetchKolCooperateInfo: builder.query<
      FetchKolCooperateResponse,
      FetchKolBasicInfoRequest
    >({
      query: ({ kolUUID }) => {
        return {
          url: `/user/kols/${kolUUID}/cowork`,
        }
      },
      transformResponse: (response: object) => {
        return camelizeKeys(response) as unknown as FetchKolCooperateResponse
      },
      providesTags: [UserAPITagType.KolCooperateInfo],
    }),
    editKolCooperateInfo: builder.mutation<void, FetchKolCooperateRequest>({
      query: (request) => {
        return {
          url: `/user/kols/${request.kolUUID}/cowork`,
          method: 'PUT',
          body: decamelizeKeys(omit(request, 'kolUUID')),
        }
      },
      invalidatesTags: [
        UserAPITagType.KolCooperateInfo,
        UserAPITagType.AgentKolList,
        InfluencerAPITagType.InfluencerInfo,
      ],
    }),
    pubnubLogin: builder.query<PubNubLoginResponse, void>({
      query: (): FetchArgs => {
        return {
          url: '/user/pubnub-login',
          method: 'POST',
        }
      },
      providesTags: [UserAPITagType.PubNubAccessToken],
      transformResponse: (response: object) => {
        return camelizeKeys(response) as unknown as PubNubLoginResponse
      },
    }),
    fetchUserMetadata: builder.query<UserMetadataResponse, void>({
      query: () => {
        return '/user/metadata'
      },
      extraOptions: {
        dataSchema: userMetadataResponseSchema,
      },
      providesTags: [UserAPITagType.UserMetadata],
    }),
    updateUserMetadata: builder.mutation<void, UserMetadata>({
      query: (body) => {
        return {
          url: '/user/metadata',
          method: 'PUT',
          body,
        }
      },
      invalidatesTags: [UserAPITagType.UserMetadata],
    }),
  }),
  overrideExisting: false,
})

export const useFetchUserStatusQuery =
  userApi.endpoints.fetchUserStatus.useQuery
export const useLazyFetchUserStatusQuery =
  userApi.endpoints.fetchUserStatus.useLazyQuery
export const useFetchUserInfoQuery = userApi.endpoints.fetchUserInfo.useQuery
export const useUpdateUserInfoMutation =
  userApi.endpoints.updateUserInfo.useMutation
export const useLazyFetchUserInfoQuery =
  userApi.endpoints.fetchUserInfo.useLazyQuery
export const useFetchContractStatusQuery =
  userApi.endpoints.fetchContractStatus.useQuery
export const useFetchAgentKolsQuery = userApi.endpoints.fetchAgentKols.useQuery
export const useFetchKolBasicInfoQuery =
  userApi.endpoints.fetchKolBasicInfo.useQuery
export const useFetchKolCooperateInfoQuery =
  userApi.endpoints.fetchKolCooperateInfo.useQuery
export const useEditKolBasicInfoMutation =
  userApi.endpoints.editKolBasicInfo.useMutation
export const useEditKolCooperateInfoMutation =
  userApi.endpoints.editKolCooperateInfo.useMutation
export const usePubnubLoginQuery = userApi.endpoints.pubnubLogin.useQuery

export const invalidateUserInfoAction = userApi.util.invalidateTags([
  UserAPITagType.UserInfo,
  UserAPITagType.PubNubAccessToken,
])
export const useFetchRecipientInfoActionQuery =
  userApi.endpoints.fetchRecipientInfo.useQuery
export const useUpdateRecipientInfoActionMutation =
  userApi.endpoints.updateRecipientInfo.useMutation
export const useCheckEmailAvailableActionMutation =
  userApi.endpoints.checkEmailAvailable.useMutation

export const {
  useFetchUserMetadataQuery,
  useUpdateUserMetadataMutation,
  useLazyFetchUserMetadataQuery,
} = userApi
